import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Tag, Button, Switch, Modal, Tooltip, Row, Col } from 'antd'
import { UserDeleteOutlined, EditOutlined } from '@ant-design/icons'
import _ from 'lodash'
import AirlinePreferencesModal from 'components/AirlinePreferencesModal'
import ManageUserSuppliers, { ManageUserSuppliersContext } from 'components/ManageUserSuppliers'

export default function FlightUserSuppliers() {
    const onBuildOptions = useCallback((suppliers, userSuppliers) => {
        const ids = userSuppliers.map(us => us.id)

        const options = _.chain(suppliers)
            .filter(s => s.active && !ids.includes(s.id))
            .reduce(
                (acc, s) => {
                    if (s.airline_code) {
                        acc.Airline.push({
                            label: `${s.airline_code} - ${s.name} (${s.source_id})`,
                            value: s.id
                        })
                    } else {
                        acc.OTA.push({
                            label: `${s.name} (${s.source_id})`,
                            value: s.id
                        })
                    }

                    return acc
                },
                {
                    Airline: [],
                    OTA: []
                }
            )
            .value()

        const groupOptions = _.chain(options)
            .keys()
            .map(label => ({
                label,
                options: options[label]
            }))
            .value()

        return groupOptions
    }, [])

    return (
        <ManageUserSuppliers product="flight" resource="flight-user" onBuildOptions={onBuildOptions}>
            <InternalFlightUserSuppliers />
        </ManageUserSuppliers>
    )
}

function InternalFlightUserSuppliers() {
    const { product, canEdit, userId, onRegisterColumns, onUpdate, dispatchEvent } = useContext(
        ManageUserSuppliersContext
    )

    const [airlinePreferencesModal, setAirlinePreferencesModal] = useState({ open: false })

    const renderAirline = (field, record) => {
        if (!record[field]) return null

        const isExceedTag = record[field] > 5

        const tags = (
            <Row gutter={[8, 8]}>
                {record[field].slice(0, 5).map(code => (
                    <Col key={code}>
                        <Tag color={record.preference_type === 'exclude' ? 'volcano' : 'gray'}>{code}</Tag>
                    </Col>
                ))}
                {isExceedTag && (
                    <Col key="__empty__">
                        <Tag color={record.preference_type === 'exclude' ? 'volcano' : 'gray'}>...</Tag>
                    </Col>
                )}
            </Row>
        )

        return !isExceedTag ? (
            tags
        ) : (
            <Tooltip
                title={record[field]}
                overlayInnerStyle={{
                    width: '250px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                {tags}
            </Tooltip>
        )
    }
    const columns = [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Code',
            defaultSortOrder: 'ascend'
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            ellipsis: true
        },
        {
            key: 'airline_code',
            dataIndex: 'airline_code',
            title: 'Airline code'
        },
        {
            key: 'markup_percent',
            dataIndex: 'markup_percent',
            title: 'Markup (%)',
            editable: true,
            inputType: 'number',
            width: '10%'
        },
        {
            key: 'route_type',
            dataIndex: 'route_type',
            title: 'Allow route type',
            render: (_, record) => {
                const types = []

                if (record.domestic) types.push('Domestic')
                if (record.international) types.push('International')

                return (
                    types.length && (
                        <Row gutter={[8, 8]}>
                            {types.map(type => (
                                <Col key={type}>
                                    <Tag color="purple">{type}</Tag>
                                </Col>
                            ))}
                        </Row>
                    )
                )
            }
        },
        {
            key: 'airline_exclude',
            dataIndex: 'airline_exclude',
            title: 'Airline Exclude',
            render: (_, record) => renderAirline('airline_exclude', record)
        },
        {
            key: 'airline_include',
            dataIndex: 'airline_include',
            title: 'Airline Include',
            render: (_, record) => renderAirline('airline_include', record)
        },
        {
            key: 'inactive',
            dataIndex: 'inactive',
            title: 'Status',
            render: (_, record) => {
                return (
                    <Switch
                        disabled={!canEdit}
                        checked={!record.inactive}
                        onClick={() => onShowChangeStatusConfirm(record)}
                    />
                )
            }
        },
        {
            key: 'action',
            title: 'Action',
            render: (_, record) => {
                return (
                    <Row gutter={[8, 8]}>
                        <Col>
                            <Tooltip title="Airline Preferences">
                                <Button
                                    type="primary"
                                    disabled={!canEdit}
                                    icon={<EditOutlined />}
                                    onClick={() => setAirlinePreferencesModal({ open: true, payload: record })}
                                />
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title="Remove">
                                <Button
                                    type="danger"
                                    disabled={!canEdit}
                                    icon={<UserDeleteOutlined />}
                                    onClick={() => onShowRemoveConfirm(record)}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                )
            }
        }
    ]

    const onShowChangeStatusConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to turn ${record.inactive ? 'on' : 'off'} ${record.name}`,
            onOk: async () => {
                dispatchEvent('status', record)
            }
        })
    }

    const onShowRemoveConfirm = record => {
        Modal.confirm({
            maskClosable: true,
            title: `Are you sure to remove ${record.name}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                dispatchEvent('remove', record)
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onRegisterColumns(columns), [])

    return (
        <AirlinePreferencesModal
            isOpen={airlinePreferencesModal.open}
            product={product}
            userId={userId}
            record={airlinePreferencesModal.payload}
            onClose={() => setAirlinePreferencesModal({ open: false })}
            onUpdate={onUpdate}
        />
    )
}
